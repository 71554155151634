.alert {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 9999;
    font-size: 12px;
    color : #666d92;
    
    .alert-container {
        background-color: #fff;
        padding: 20px;
        border : 0px solid #cecece;
        border-radius: 5px;
        box-shadow: 8px 7px 14px -3px rgba(0,0,0,0.57);
        -webkit-box-shadow: 8px 7px 14px -3px rgba(0,0,0,0.57);
        -moz-box-shadow: 8px 7px 14px -3px rgba(0,0,0,0.57);        
        transform: translateX(0%);
        transition: all 0.3s ease-in;              
        justify-content: center;
        align-items: center;    

        .icon {
            margin-right: 10px;
            font-size: 15px;            
        }

        .icon.success {
            color : rgb(1, 184, 26);
        }

        .icon.danger {
            color : rgb(255, 15, 15);
        }

        .icon.info {
            color : rgb(0, 170, 255);
        }

        .alert-content {
            padding-right: 20px;
            padding-left: 20px;
        }     
        
        .icon-close {
            font-size: 15px;
        }
        
    }

    .alert-container.alert-close {                
        transform: translateX(0%);
        transition: all 0.3s ease-out;    
        scale: 0;        
        .alert-content {
            padding-right: 0 !important;
            padding-left: 0 !important;
        }     
    }

    .alert-container.danger { 
        background-color:  rgb(255, 205, 205) !important;
        color : rgb(255, 15, 15);
    }
    .alert-container.success { 
        background-color:  rgb(224, 248, 229) !important;
        color : rgb(1, 184, 26);
    }
    .alert-container.info { 
        background-color:  rgb(223, 244, 254) !important;
        color : rgb(0, 170, 255);
    }
}

#root {
    overflow-y: hidden;
}