@import "button";
@import "table";
@import "_alert";

.footer {
    position: static;
    bottom: 0;
}

.main-content {
    min-height: 87.9vh;
}

.table {
    tbody tr td {
        padding: 16px;
        border-bottom: 1px solid #f0f0f0;
        transition: background .3s;
    }
    thead tr th {
        background-color: rgba(0,0,0,.06);
        font-weight: 500;
        height: 1.6em;
        padding: 10px 15px;
    }

    thead tr th:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 6px;
    }
    
    thead tr th:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 6px        
    }
}

.sidebaropen li.active {
    background-color: #faf0ff;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    color : #8231d3;
}

.sidebar {
    li {
        font-size: 13px;
        font-weight: 500;
        color : #000;
    }
    li:hover {
        color : #8231d3;
    }
}

.sidebarclose {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sidebaropen {
    padding-right: 30px;
}

.profile-menu {
    ul {
        padding-left : 0 !important;
        li:first-child {
            margin-top: 10px;
        }
        li {
            color : rgb(116, 116, 116);            
            padding-bottom: 5px;            
            width: 100%;
            padding: 10px 20px;
            font-size: 13px;
            div div:last-child {
                padding-left: 20px;
            }
            
        }
        li:hover {
            color : #8231d3;
        }
        li.gray {
            background-color: rgb(244, 245, 247);
            color :   rgb(116, 116, 116);
            text-align: center;
            margin-top: 20px;
            cursor: pointer;
        }
    }
}

.sidebar .submenu.isOpen {    
    opacity: 1;
    overflow: hidden;
    height: auto;
    transition: height 0s linear 0s, opacity .5s;
}

.sidebar .submenu.isClose {
    opacity: 0;
    height: 0;    
}