.table {
    thead tr th {
        text-align: left;
    }
    thead tr th.text-right {
        text-align: right;
    }
    thead tr th.text-center {
        text-align: center;
    }
}