.button {    
    padding : 8.4px 14px;   
}

.button.purple {
    background-color: #8231d3;
    color : #fff;
}

.button.dark {
    background-color: rgb(10, 10, 10);
    color : #fff;
}

.button.light {
    background-color: rgb(170, 166, 166);
    color : #fff;
}

.button.success {
    background-color:rgb(1, 184, 26);
    color : #fff;
}

.button.success-disabled {
    background-color:rgb(117, 225, 131);
    color : #ededed;
}

.button.danger {
    background-color: rgb(255, 15, 15);
    color : #fff;
}

.button.purple-light {
    background-color: rgba(130, 49, 211, 0.082) !important;
    color : rgb(130, 49, 211);
}

.button.primary-light {    
    background-color: rgba(130, 49, 211, 0.082);
    color : rgb(130, 49, 211);
}

.button.secondary-light {
    background-color: rgba(88, 64, 255, 0.082) !important;
    color : rgb(88, 64, 255);
}

.button.success-light {
    background-color: rgba(1, 184, 26, 0.082) !important;
    color : rgb(1, 184, 26);
}

.button.info-light {
    background-color: rgba(0, 170, 255, 0.082) !important;
    color : rgb(0, 170, 255);
}

.button.warning-light {
    background-color: rgba(250, 139, 12, 0.082) !important;
    color : rgb(250, 139, 12);
}

.button.danger-light {
    background-color: rgba(255, 15, 15, 0.082) !important;
    color : rgb(255, 15, 15);
}

.button.dark-light {
    background-color: rgba(10, 10, 10, 0.082);
    color : rgb(10, 10, 10);;
}

.button.primary-outline {
    border: 1px solid rgb(130, 49, 211);
    background-color: white;
    color : rgb(130, 49, 211);
}

.button.danger-outline {
    border: 1px solid rgb(255, 15, 15);
    background-color: white;
    color : rgb(255, 15, 15);
}

.button.secondary-outline {
    border: 1px solid rgb(88, 64, 255);
    background-color: white;
    color : rgb(88, 64, 255);
}

.button.success-outline {
    border: 1px solid rgb(1, 184, 26);
    background-color: white;
    color : rgb(1, 184, 26);
}

.button.info-outline {
    border: 1px solid rgb(0, 170, 255);
    background-color: white;
    color : rgb(0, 170, 255);
}

.button.warning-outline {
    border: 1px solid rgb(250, 139, 12);
    background-color: white;
    color : rgb(250, 139, 12);
}

.button.dark-outline {
    border: 1px solid rgb(10, 10, 10);
    background-color: white;
    color : rgb(10, 10, 10);
}

.button.btn-icon {
    padding: 0.15rem 0.45rem !important;
    font-size: 12px;
}