
.table-border  tbody   tr  td,
.table-border  thead   tr  th,
.table-border  thead   tr  td{
    border : 1px solid #cecece;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 6px;
    padding-bottom: 6px;
}

.button-group .button:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-right: none !important;
}

.button-group .button:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;    
}

.button-group .btn-icon:last-child {    
    border-right: solid 1px !important;
}



.button svg {
    margin-right: 3px;
}

.button-group .btn-create {
    padding: 0.70rem 1.25rem;
}



.btn-icon {
    padding: 0.15rem 0.45rem !important;
    font-size: 12px;
}

.pagination div:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;

}

.pagination div{
    border-right: none ;
}

.pagination div:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-right: 1px solid rgb(161 161 170) !important;
}

.pagination .active {
    background-color: rgb(96 165 250);
    color : #fff;
}

.label-development {
    background-color: #f8d7da;
    width: 25px;
    height: 200px;
    position: absolute;
    right: 20px;
    top: -50px;
    transform: rotate(-45deg);
    transform-origin: right, top;    
    writing-mode:vertical-rl;
    padding-top: 50px;    
    padding-left: 20px;
    color : #721c24;
    z-index: 30;    
}

.ant-popover-placement-bottom .ant-popover-arrow, .ant-popover-placement-bottomLeft .ant-popover-arrow, .ant-popover-placement-bottomRight .ant-popover-arrow {
    top: 0;
    transform: translateY(-100%);
}

.ant-popover-arrow {
    background: 0 0;
    display: block;
    height: 22px;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    width: 22px;
}

.profile-menu.m-visible {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 300ms, opacity 300ms;
}

.profile-menu.m-invisible {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s linear 0s, opacity 300ms;
}

.sidebar .submenu.isOpen {    
    opacity: 1;
    overflow: hidden;
    height: auto;
    transition: height 0s linear 0s, opacity .5s;
}

.sidebar .submenu.isClose {
    opacity: 0;
    height: 0;    
}

.bubble {
    /* (A1) FONT */
    font-size: 1.1em;
   
    /* (A2) COLORS */
    color: #fff;    
   
    /* (A3) DIMENSIONS + POSITION */
    position: relative;    
    border-radius: 10px;
    border: 1px solid #cecece;
    max-width: 320px;
    border : solid .5px #eeeeee;
    transition:visibility 0.3s linear,opacity 0.3s linear;
    /* box-shadow: 8px 7px 14px -3px rgba(0,0,0,0.57);
    -webkit-box-shadow: 8px 7px 14px -3px rgba(0,0,0,0.57);
    -moz-box-shadow: 8px 7px 14px -3px rgba(0,0,0,0.57); */
  }
  
  /* (B) ADD SPEECH "CALLOUT TAIL" */
  /* (B1) USE ::AFTER TO CREATE THE "TAIL" */
  .bubblee::after {
    /* (B1-1) ATTACH TRANSPARENT BORDERS */
    content: "";
    border: 20px solid transparent;
   
    /* (B1-2) NECESSARY TO POSITION THE "TAIL" */
    position: absolute;
    border-bottom-color: white;
    border-top: 0;
   
    /* (C1-2) POSITION AT TOP */
    top: -20px; 
    left: 70%;
    margin-left: -20px;    
    border-left: 1px solid #cecece;
  }
   



  